
import {
  apiFactoryLists,
  apiFactoryStatus,
  apiFactoryDel,
  apiModelList,
  apiOrderLists,
} from "@/api/shop";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
  },
})
export default class Factory extends Vue {
  /** S Data **/

  apiFactoryLists = apiFactoryLists;

  pager: RequestPaging = new RequestPaging();
  pager2: RequestPaging = new RequestPaging();
  pager3: RequestPaging = new RequestPaging();

  searchObj: Object = {
    name: "",
  };

  modelObj: any = {
    title: "",
    cate_id: "",
    factory_id: "",
  };

  orderObj = {
    copysid_id: "",
    factory_id: "",
  };

  categoryList: any[] = [];
  dialogTableVisible = false;
  dialogTableVisible2 = false;
  /** E Data **/

  /** S Methods **/

  // 获取工厂列表
  getFactoryList(): void {
    this.pager.request({
      callback: apiFactoryLists,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 获取模型列表
  async getModelLists(id: any) {
    this.modelObj.factory_id = id;
    this.pager2.request({
      callback: apiModelList,
      params: {
        ...this.modelObj,
      },
    });
  }

  openDialog(item: any) {
    this.dialogTableVisible = true;
    this.getModelLists(item.id);
  }

  // 获取订单列表
  async getOrderLists(id: any) {
    this.orderObj.factory_id = id;
    this.pager3.request({
      callback: apiOrderLists,
      params: {
        order_status: 1,
        ...this.orderObj,
      },
    });
  }

  openDialog2(item: any) {
    this.dialogTableVisible2 = true;
    this.getOrderLists(item.id);
  }

  // 修改状态
  async shopSwitchStatusEdit(status: any, id: number): Promise<void> {
    await apiFactoryStatus({ id, is_show: status });
    this.getFactoryList();
  }

  // 删除商城
  async onShopDelete(row: any): Promise<void> {
    await apiFactoryDel({ id: row.id });
    this.getFactoryList();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getFactoryList();
  }

  // 去编辑
  toFactoryEdit(id: number | any): void {
    this.$router.push({
      path: "/factory/factory_edit",
      query: {
        id: id as any,
        mode: PageMode.EDIT,
      },
    });
  }

  // 去编辑发件人模板
  toFactoryAddress(id: number | any): void {
    this.$router.push({
      path: "/factory/send_template",
      query: {
        id: id as any,
        mode: PageMode.EDIT,
      },
    });
  }
  // 修改超级管理员
  toSuperAdminUpdate(id: number | any): void {
    this.$router.push({
      path: "/factory/modify_account",
      query: {
        id: id,
      },
    });
  }

  /** E Methods **/

  /** S ods **/
  created() {
    this.getFactoryList();
  }
}
